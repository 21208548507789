:global {
  .paragraph-1 {
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.22px;
  }

  .paragraph-2 {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.35px;
  }

  .paragraph-2 b {
    font-weight: 700;
    letter-spacing: 0.62px;
  }

  .paragraph-3 {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.42px;
    line-height: 16px;
  }

  .line-height-1 {
    line-height: 1;
  }

  :global(html[lang='fr']) {
    .postStatus {
      width: 100px;
    }
  }

  .postStatus {
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 2px;
    width: 86px;
    text-align: center;
  }

  .textInAdCard {
    color: rgba(0, 0, 0, 0.6);
  }

  .caption {
    color: var(--color-neutral);
  }

  .mac.firefox {
    .paragraph-1 {
      font-weight: 300;
    }

    .paragraph-2 {
      font-weight: 300;
    }

    .paragraph-2 b {
      font-weight: 700;
    }

    .paragraph-3 {
      font-weight: 300;
    }

    .ctaLabel b {
      font-weight: 500;
    }
  }

  .mac.firefox .postStatus {
    font-weight: 300;
  }

  .mac.firefox .postText {
    font-weight: 200;
  }

  .win {
    .postText {
      font-weight: 300;
    }

    .postStatus {
      font-weight: 200;
    }

    .textInAdCard {
      font-weight: 600;
    }

    .paragraph-2 b {
      font-weight: 600;
    }
  }

  .win.chrome {
    .paragraph-2 b {
      font-weight: 700;
    }

    .ctaLabel b {
      font-weight: 600;
    }
  }

  .win.firefox {
    .paragraph-2 b {
      font-weight: 700;
    }

    .textInAdCard {
      font-weight: 400;
    }

    .ctaLabel b {
      font-weight: 600;
    }

    .postStatus {
      margin-top: 1px;
    }
  }

  .win.edge {
    .paragraph-2 b {
      font-weight: 700;
    }

    .ctaLabel b {
      font-weight: 600;
    }
  }

  .link {
    color: var(--color-primary);
    text-decoration: none;
    cursor: pointer;
  }

  .reply-btn {
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0;
  }

  .no-touch .link:hover {
    color: var(--color-primary-2);;
  }

  .link:hover, .link:active, .link:focus {
    outline: none;
  }

  .textpage_p {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 20px;

    .indent {
      margin-left: 30px;
    }

    b {
      font-weight: 700;
      letter-spacing: .62px;
    }

    ul {
      margin-bottom: 10px;
    }

    li {
      display: flex;
      align-items: flex-start;
      margin-left: 20px;
      margin-bottom: 10px;

      &:before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 24px;
        margin-top: 8px;
        background: var(--color-secondary);
        border-radius: 50%;
        flex-shrink: 0;
      }
    }

    p {
      margin-bottom: 20px;
    }

    a {
      color: var(--color-primary);
      text-decoration: none;
      cursor: pointer;
      position: relative;
      z-index: 1;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 40px;
        z-index: -1;
        cursor: pointer;
      }
    }

    .container-cookie {
      @media(max-width: 768px) {
        display: none;
      }
      display: flex;
      justify-content: space-around;
      border: 1px solid var(--color-placeholder);
      border-bottom: none;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.08462398499250412px;
      text-align: left;
      color: var(--color-text);

      &:first-of-type {
        color: rgba(0, 0, 0, 0.6);

        border-radius: 8px 8px 0px 0px;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: left;


      }

      &:last-of-type {
        border-bottom: 1px solid var(--color-placeholder);;
        border-radius: 0px 0px 8px 8px;
      }

      div {
        &:nth-of-type(1) {
          width: 15%;
          padding-bottom: 18px;
          padding-top: 18px;
          padding-left: 24px;
          padding-right: 20px;
          word-break: break-word;
        }

        &:nth-of-type(2) {
          width: 20%;
          padding-bottom: 18px;
          padding-top: 18px;
          padding-left: 20px;
          padding-right: 20px;
          word-break: break-word;
        }

        &:nth-of-type(3) {
          width: 48%;
          padding-bottom: 18px;
          padding-top: 18px;
          padding-left: 20px;
          padding-right: 20px;
        }

        &:nth-of-type(4) {
          width: 17%;
          padding-bottom: 18px;
          padding-top: 18px;
          padding-left: 20px;
          padding-right: 24px;
        }
      }
    }

    .mobile-cookie-container {
      @media(min-width: 769px) {
        display: none;
      }
      border: 1px solid rgba(228, 228, 228, 1);

      &:first-of-type {
        border-radius: 8px 8px 0px 0px;
      }

      &:last-of-type {
        border-radius: 0px 0px 8px 8px;
      }

      .mobile-cookie {
        border-bottom: 1px solid rgba(228, 228, 228, 1);
        display: flex;
        justify-content: space-between;

        div {
          padding: 18px 16px;

          &:nth-of-type(1) {
            text-align: left;
            font-family: 'Nunito Sans', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;


          }

          &:nth-of-type(2) {
            font-family: 'Nunito Sans', sans-serif;
            font-size: 14px;
            word-break: break-word;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
            text-align: right;
            color: var(--color-text);


          }
        }
      }
    }
  }
}
