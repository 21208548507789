.bottomSnackbar {
  &:first-letter {
    text-transform: uppercase;
  }

  bottom: 8px !important;
  @media(min-width: 960px) {
    bottom: 50px !important;
  }

  @media (max-width: 959px) {
    //bottom: 8px !important;
    //left: 8px !important;
    //right: 8px !important;
  }
}

.bottomSnackbar > div {
  border-radius: 4px;
  //font-family: "Nunito Sans";
}

:global(.iphoneX) .bottomSnackbar,
:global(.android) .bottomSnackbar,
:global(.iphone) .bottomSnackbar {
  bottom: 8px !important;
  left: 8px !important;
  right: 8px !important;
  transform: translateX(0);
}

@media (orientation: landscape) {
  :global(.iphoneX) .bottomSnackbar {
    //bottom: 16px !important;
    //left: 50% !important;
    //right: auto !important;
    //transform: translateX(-50%) !important;
  }
}

@media (orientation: portrait) {
  :global(.ipad) .bottomSnackbar {
    //bottom: 8px !important;
    //left: 8px !important;
    //right: 8px !important;
  }
  :global(.iphoneX) .bottomSnackbar {
    bottom: 16px !important;
  }
}
