.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: -7px;

  p {
    color: var(--color-text)!important;
    font-size: 22px!important;
    font-weight: 600!important;
    font-style: normal;
    letter-spacing: 0.25px;
    line-height: normal;
    text-align: center;
    margin: 26px 0 13px!important;
  }

  & > span {
    color: rgba(0, 0, 0, 0.6)!important;
    font-size: 15px!important;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.25px;
    line-height: 20px!important;
    text-align: center;
    margin-bottom: 27px!important;
    display: block;
  }

  button {
    min-width: 228px !important;
    max-width: 228px !important;
    display: block;
    margin: 0 auto 33px !important;
  }

  :global(button.fr) {
    min-width: 250px !important;
    max-width: 250px !important;
  }

  @media (max-width: 420px) {
    svg {
      width: 100%;
      height: auto;
    }
  }
}
