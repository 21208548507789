a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

:focus {
  outline: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:after, blockquote:before, q:after, q:before {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

audio, canvas, video {
  display: inline-block;
  max-width: 100%; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: 0; }

a:active, a:hover {
  outline: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline; }

button, input {
  line-height: normal; }

button, select {
  text-transform: none; }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button, html, input, select, textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: .2em 0;
  background: #ccc;
  color: #000;
  padding: .2em 0; }

a:link, a:visited {
  text-decoration: none; }

button:disabled {
  background: none; }

button {
  background: none;
  border: none;
  padding: 0; }

:global .white {
  color: #fff; }

:global .h1 {
  font-size: 65px;
  font-weight: 300;
  letter-spacing: -0.3px;
  line-height: 70px; }

:global .h2 {
  font-size: 37px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 49px; }

:global .h3 {
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0px; }

:global .h3 b {
  font-weight: 700;
  letter-spacing: 0.4px; }

:global .h4 {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.1px; }

:global .mac.firefox .h1 {
  font-weight: 200; }

:global .mac.firefox .h2 {
  font-weight: 300; }

:global .mac.firefox .h3 {
  font-weight: 300; }

:global .mac.firefox .h3 b {
  font-weight: 600; }

:global .mac.firefox .h4 {
  font-weight: 500; }

:global .mac.firefox .postHeading {
  font-weight: 300; }

:global .win .postHeading {
  font-weight: 500; }

:global .win .h3.white b {
  font-weight: 600; }

:global .postHeading {
  text-overflow: ellipsis;
  white-space: nowrap; }

:root {
  --color-success: #45AD7B;
  --color-primary-prev: #50B772;
  --color-primary: #EB599C;
  --color-primary-hover: #C13E7A;
  --color-primary-border: #EB599C;
  --color-primary-2: #A52D64;
  --color-secondary: #4A8BEB;
  --color-secondary-2: #2169D1;
  --color-secondary-3: #254676;
  --color-secondary-4: #4A8BEB;
  --color-secondary-5: #254676;
  --color-secondary-darken: #4A8BEB;
  --color-background: #f8f8f8;
  --color-background-hover: #FDEEF5;
  --color-hover-dark-primary: #C13E7A;
  --color-text: rgba(0, 0, 0, 0.87);
  --color-text-body: rgba(0, 0, 0, 0.6);
  --color-neutral: rgba(0, 0, 0, 0.38);
  --color-neutral-2: #f0f0f0;
  --color-neutral-3: #ADADAD;
  --color-neutral-4: #fafafa;
  --color-neutral-5: #4a629e;
  --color-placeholder: #e4e4e4;
  --color-border: #e9e9e9; }

html, body {
  position: relative;
  width: 100%; }

body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  background: var(--color-background); }

.container {
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px;
  margin: auto;
  position: relative; }
  @media (max-width: 560px) {
    .container {
      padding: 0 16px; } }

.grecaptcha-badge {
  visibility: hidden; }

.meta-data {
  display: none; }

.body_colored {
  background: var(--color-background);
  min-height: calc(100vh - 300px);
  overflow: hidden; }

.App {
  min-height: calc(100vh);
  display: flex;
  flex-direction: column; }

.grid-item > div {
  width: 100%;
  height: 100%; }

.closeBtn {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  background-color: #fff; }
  .closeBtn svg {
    width: 25px;
    height: 25px;
    fill: var(--color-neutral); }

.ios .closeBtn {
  top: 18px; }

.login-modal-container {
  display: flex;
  flex-direction: column;
  padding: 30px 33px 30px 31px;
  max-width: 309px;
  text-align: center; }
  .login-modal-container button:last-child {
    margin-bottom: 4px; }
  @media (max-width: 650px) {
    .login-modal-container {
      max-width: 288px;
      padding-bottom: 28px; }
      .login-modal-container .paragraph-2 {
        padding-right: 10px;
        padding-left: 10px; } }

.login-modal-container .h4 {
  margin-bottom: 16px; }

.mac.firefox .login-modal-container .h4 {
  font-weight: 400; }

.login-modal-container .paragraph-2 {
  margin-bottom: 28px;
  color: rgba(0, 0, 0, 0.6); }

.closeBtn-modal {
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  right: 0;
  top: 0;
  position: absolute;
  width: 24px;
  height: 24px;
  padding: 8px;
  z-index: 10; }
  @media (max-width: 650px) {
    .closeBtn-modal {
      padding: 12px; } }
  .closeBtn-modal.close-notification {
    top: 5px;
    display: none; }

.modalLogin-wrapper .closeBtn-modal {
  padding: 24px;
  top: 0;
  right: 0; }

@media (max-width: 767px) {
  .closeBtn-modal.close-notification {
    display: block; } }

@media only screen and (orientation: landscape) {
  .device .closeBtn-modal.close-notification {
    display: block; } }

@media only screen and (orientation: portrait) {
  .device .closeBtn-modal.close-notification {
    display: block; } }

.no-touch .closeBtn-modal:hover {
  opacity: 0.5; }

.win .drop-down-block li {
  font-weight: 400; }

.win .delete-alert-wrapper .alert-link {
  font-weight: 600; }

.drop-down-block li {
  color: var(--color-text);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: Nunito Sans;
  cursor: pointer;
  position: relative;
  padding: 16px 16px 15px; }
  .drop-down-block li:hover {
    background: var(--color-background-hover); }
  @media (hover: none) {
    .drop-down-block li {
      background: #fff; } }
  .drop-down-block li .icon {
    width: 21px;
    height: 23px;
    right: 18px;
    top: 13px;
    position: absolute; }
    .drop-down-block li .icon img {
      width: 100%;
      height: 100%; }
  .drop-down-block li.add-child-link .icon svg, .drop-down-block li.add-child-link .icon path {
    width: 100%;
    height: 100%; }
  .drop-down-block li.my-account-link .icon {
    width: 23px;
    border-radius: 50%; }
  .drop-down-block li.my-account-link svg {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important; }

.square {
  width: 250px;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center; }

.green-bg .fadeo {
  background-image: linear-gradient(270deg, var(--color-background-hover) 0%, rgba(230, 244, 234, 0) 100%) !important; }

.drop-down-block li a {
  color: inherit; }

#upload-image {
  display: none; }

.delete-modal-wrapper {
  width: 328px;
  margin: 0 auto; }
  @media (max-width: 650px) {
    .delete-modal-wrapper {
      width: 288px; } }
  .delete-modal-wrapper .h4 {
    line-height: 1.4;
    margin-top: -5px;
    margin-bottom: 14px; }

.delete-alert-wrapper {
  width: 389px; }
  .delete-alert-wrapper .login-modal-container {
    text-align: left;
    max-width: 389px;
    padding: 26px 20px 19px 24px; }
  .delete-alert-wrapper .group-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px; }
  .delete-alert-wrapper .alert-link {
    letter-spacing: 0.1px;
    font-weight: 700;
    color: var(--color-primary);
    font-size: 18px;
    margin-left: 29px;
    cursor: pointer; }
    .delete-alert-wrapper .alert-link:hover {
      color: var(--color-primary-hover); }
  @media (max-width: 415px) {
    .delete-alert-wrapper {
      width: 280px; }
      .delete-alert-wrapper .login-modal-container {
        width: 280px;
        box-sizing: border-box;
        text-align: center; }
      .delete-alert-wrapper .group-button {
        align-items: center;
        justify-content: space-around; }
        .delete-alert-wrapper .group-button .alert-link {
          margin-left: 0;
          margin-bottom: 10px; } }

.mla {
  margin-left: auto; }

p, span {
  word-break: break-word;
  word-wrap: break-word;
  hyphens: auto; }

.modal-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: stretch !important; }
  @media (max-height: 500px) {
    .modal-wrapper {
      justify-content: stretch !important; } }
  @media (max-height: 650px) {
    .modal-wrapper.vote-wrapper {
      justify-content: stretch !important; } }
  @media (max-height: 650px) and (min-width: 768px) {
    .modal-wrapper.vote-wrapper .vote-popup {
      margin-bottom: 20px; } }
  @media (max-width: 767px) {
    .modal-wrapper.fullHeight {
      padding: 0 !important;
      bottom: auto !important; }
      .modal-wrapper.fullHeight .overflow-auto {
        background: #fff;
        overflow: auto; } }

@media (max-width: 767px) {
  .iphone .modal-wrapper.fullHeight {
    bottom: 0 !important; } }

@media (min-width: 767px) {
  .modal-div100vh {
    height: auto !important; } }

@media (max-height: 650px) and (min-width: 768px) {
  .firefox .vote-wrapper .vote-popup,
  .edge .vote-wrapper .vote-popup,
  .safari .vote-wrapper .vote-popup {
    margin-bottom: 20px; } }

div[style="top:0px;left:0px;height:100%;width:100%;position:fixed;background-color:#000;opacity:0.5;"] {
  z-index: 999; }

.alignItemsEnd {
  align-items: flex-end; }

@media (max-width: 767px) {
  .mobile-d-none {
    display: none; } }

.mobile-d-block {
  display: none; }
  @media (max-width: 767px) {
    .mobile-d-block {
      display: block; } }

.no-wrap {
  white-space: nowrap; }

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation; }

.preloader-placeholder {
  background-color: var(--color-placeholder);
  border-radius: 18px; }

.input-preloader-placeholder {
  margin-left: 8px;
  padding-right: 3px;
  width: 25px;
  height: 1px; }

.post-page .input-preloader-placeholder, .my-account-page .input-preloader-placeholder {
  display: none; }

i {
  font-style: italic; }

.preloaderHolder {
  opacity: 0.35; }

.show-more-text {
  margin-left: auto;
  cursor: pointer; }

.no-touch .show-more-text:hover {
  color: var(--color-primary-hover); }

.displayNone {
  display: none; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }

.page-header {
  color: var(--color-text);
  font-family: "Nunito Sans", 'sans-serif';
  font-size: 37px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 52px;
  text-align: center; }
  @media (max-width: 768px) {
    .page-header {
      font-size: 26px;
      line-height: normal; } }

.pupswipe.App {
  min-height: initial; }

@media (max-width: 560px) {
  .pupswipe .App {
    min-height: calc(100vh - 55px); }
  .pupswipe.iphone .App {
    min-height: calc(100vh - 110px); } }

#feedback-button {
  position: fixed;
  top: 50%;
  right: 0;
  background: var(--color-secondary);
  z-index: 999999;
  transform: translate(50%, -300%) rotate(-90deg);
  transform-origin: right bottom;
  padding: 8px 16px;
  border-radius: 8px 8px 0 0;
  color: var(--color-background);
  animation: IsShowFeedback 200ms 500ms forwards;
  user-select: none; }
  #feedback-button:active, #feedback-button:focus-visible {
    background: var(--color-secondary-2); }
  @media (hover: hover) {
    #feedback-button:hover {
      background: var(--color-secondary-2); } }

@keyframes IsShowFeedback {
  100% {
    transform: translate(0, -300%) rotate(-90deg); } }
