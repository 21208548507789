.wrapper {
  padding: 60px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;

  tspan {
    text-transform: uppercase;
  }

  text {
    text-transform: uppercase;
  }

  & > svg {
    position: absolute;
    left: -9px;
    top: -9px;
  }
}

.new {
  position: absolute;
  font-size: 23px;
  font-weight: 700;
  color: #fff;
  transform: rotate(-45deg);
  left: 15px;
  top: 28px;
  z-index: 1;
  text-transform: uppercase;
}

:global(html[lang='no']) {
  .new {
    left: 27px;
    top: 25px;

    @media (max-width: 560px) {
      left: 14px;
      top: 15px;
    }
  }
}

:global(html[lang='se']) {
  .new {
    left: 27px;
    top: 25px;

    @media (max-width: 560px) {
      left: 14px;
      top: 15px;
    }
  }
}

.decor {
  svg {
    width: 100%;
    height: auto;
  }
}

.title {
  max-width: 600px;
  margin: 27px 0 11px;
  color: var(--color-text);
  font-size: 26px;
  text-align: center;

  br {
    display: none;
  }
}

.description {
  max-width: 600px;
  color: var(--color-text);
  font-size: 17px;
  letter-spacing: 0.5px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 31px;
}

.wrapper .playNow {
  min-width: 250px;
  width: auto;
  height: 36px;
  margin-bottom: 16px;
}

.noThanks {
  color: var(--color-primary);
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: center;

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary-hover);
    }
  }
}

@media (max-width: 560px) {
  .wrapper > svg {
    width: 80px;
    height: auto;
    top: -5px;
    left: -5px;
  }

  .new {
    left: 10px;
    top: 15px;
    font-size: 12px;
  }

  .title br {
    display: inline;
  }

  .wrapper {
    padding: 40px 16px;
  }

  .title {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: normal;
    text-align: center;
    margin: 20px 0 14px;
  }

  .description {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 25px;
    color: rgba(0, 0, 0, 0.6);
  }
}
