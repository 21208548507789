.Circle {
  position: relative;
  width: 57px!important;
  height: 57px!important;
  margin: 10px auto 30px auto;
}
.Circle div {
  box-sizing: border-box;
  display: block;
  position: absolute!important;
  width: 44px!important;
  height: 44px!important;
  margin: 6px;
  border: 4px solid var(--color-secondary-5)!important;
  border-radius: 50%!important;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color-secondary-5) transparent transparent transparent!important;
}
.Circle div:nth-child(1) {
  animation-delay: -0.45s;
}
.Circle div:nth-child(2) {
  animation-delay: -0.3s;
}
.Circle div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
