.wrapper {
  width: 350px;
  background-color: #ffffff;
  padding: 32px 32px 0;
  box-sizing: border-box;
}

@media (max-width: 420px) {
  .wrapper {
    max-width: 288px;
    width: 288px;
  }
}
