:global {
  .white {
    color: #fff;
  }

  .h1 {
    font-size: 65px;
    font-weight: 300;
    letter-spacing: -0.3px;
    line-height: 70px;
  }

  .h2 {
    font-size: 37px;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 49px;
  }

  .h3 {
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0px;
  }

  .h3 b {
    font-weight: 700;
    letter-spacing: 0.4px;
  }

  .h4 {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.1px;
  }

  .mac.firefox {
    .h1 {
      font-weight: 200;
    }

    .h2 {
      font-weight: 300;
    }

    .h3 {
      font-weight: 300;
    }

    .h3 b {
      font-weight: 600;
    }

    .h4 {
      font-weight: 500;
    }

    .postHeading {
      font-weight: 300;
    }
  }

  .win {
    .postHeading {
      font-weight: 500;
    }

    .h3.white b {
      font-weight: 600;
    }
  }

  .postHeading {
    text-overflow: ellipsis;
    //overflow: hidden;
    white-space: nowrap;
  }
}